/*
 * Choices
 */

.choices[data-type*="select-one"] .choices__button:focus {
  box-shadow: 0px 0px 0px 2px #e9ecef;
}

.choices__inner {
  background-color: white;
  border-radius: 0.375rem;
}

.choices__list--multiple .choices__item {
  border-radius: 3px;
  background-color: #e9ecef;
  border: 1px solid rgba(0, 40, 100, 0.12);
  color: #495057;
}

.choices__list--multiple .choices__item.is-highlighted {
  background-color: rgba(0, 40, 100, 0.12);
  border: 1px solid rgba(0, 40, 100, 0.12);
}

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  border-left: 1px solid rgba(0, 40, 100, 0.3);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzAzMDMwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
}