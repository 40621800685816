.selectize-control.plugin-drag_drop.multi>.selectize-input>div.ui-sortable-placeholder {
  visibility: visible !important;
  background: #f2f2f2 !important;
  background: rgba(0, 0, 0, 0.06) !important;
  border: 0 none !important;
  box-shadow: inset 0 0 12px 4px #fff;
}
.selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
  content: '!';
  visibility: hidden;
}
.selectize-control.plugin-drag_drop .ui-sortable-helper {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.selectize-dropdown-header {
  position: relative;
  padding: 5px 8px;
  border-bottom: 1px solid #d0d0d0;
  background: #f8f8f8;
  border-radius: 3px 3px 0 0;
}
.selectize-dropdown-header-close {
  position: absolute;
  right: 8px;
  top: 50%;
  color: #495057;
  opacity: 0.4;
  margin-top: -12px;
  line-height: 20px;
  font-size: 20px !important;
}
.selectize-dropdown-header-close:hover {
  color: #000;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup {
  border-right: 1px solid #f2f2f2;
  border-top: 0 none;
  float: left;
  box-sizing: border-box;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
  border-right: 0 none;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup:before {
  display: none;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup-header {
  border-top: 0 none;
}
.selectize-control.plugin-remove_button [data-value] {
  position: relative;
  padding-right: 24px !important;
}
.selectize-control.plugin-remove_button [data-value] .remove {
  z-index: 1;
  /* fixes ie bug (see #392) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 17px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  color: inherit;
  text-decoration: none;
  vertical-align: middle;
  display: inline-block;
  padding: 2px 0 0 0;
  border-left: 1px solid #d0d0d0;
  border-radius: 0 2px 2px 0;
  box-sizing: border-box;
}
.selectize-control.plugin-remove_button [data-value] .remove:hover {
  background: rgba(0, 0, 0, 0.05);
}
.selectize-control.plugin-remove_button [data-value].active .remove {
  border-left-color: #cacaca;
}
.selectize-control.plugin-remove_button .disabled [data-value] .remove:hover {
  background: none;
}
.selectize-control.plugin-remove_button .disabled [data-value] .remove {
  border-left-color: #fff;
}
.selectize-control.plugin-remove_button .remove-single {
  position: absolute;
  right: 28px;
  top: 6px;
  font-size: 23px;
}
.selectize-control {
  position: relative;
  padding: 0;
  border: 0;
}
.selectize-dropdown, .selectize-input, .selectize-input input {
  color: #495057;
  font-family: inherit;
  font-size: 15px;
  line-height: 18px;
  -webkit-font-smoothing: inherit;
}
.selectize-input, .selectize-control.single .selectize-input.input-active {
  background: #fff;
  cursor: text;
  display: inline-block;
}
.selectize-input {
  border: 1px solid rgba(0, 40, 100, 0.12);
  padding: 0.5625rem 0.75rem;
  display: inline-block;
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  border-radius: 3px;
  transition: .3s border-color, .3s box-shadow;
}
.selectize-control.multi .selectize-input.has-items {
  padding: 7px 0.75rem 4px 7px;
}
.selectize-input.full {
  background-color: #fff;
}
.selectize-input.disabled, .selectize-input.disabled * {
  cursor: default !important;
}
.selectize-input.focus {
  border-color: #467fcf;
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
}
.selectize-input.dropdown-active {
  border-radius: 3px 3px 0 0;
}
.selectize-input>* {
  vertical-align: baseline;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.selectize-control.multi .selectize-input>div {
  cursor: pointer;
  margin: 0 3px 3px 0;
  padding: 2px 6px;
  background: #e9ecef;
  color: #495057;
  font-size: 13px;
  border: 0 solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  font-weight: 400;
}
.selectize-control.multi .selectize-input>div.active {
  background: #e8e8e8;
  color: #303030;
  border: 0 solid #cacaca;
}
.selectize-control.multi .selectize-input.disabled>div, .selectize-control.multi .selectize-input.disabled>div.active {
  color: #7d7d7d;
  background: #fff;
  border: 0 solid #fff;
}
.selectize-input>input {
  display: inline-block !important;
  padding: 0 !important;
  min-height: 0 !important;
  max-height: none !important;
  max-width: 100% !important;
  margin: 0 2px 0 0 !important;
  text-indent: 0 !important;
  border: 0 none !important;
  background: none !important;
  line-height: inherit !important;
  box-shadow: none !important;
}
.selectize-input>input::-ms-clear {
  display: none;
}
.selectize-input>input:focus {
  outline: none !important;
}
.selectize-input::after {
  content: ' ';
  display: block;
  clear: left;
}
.selectize-input.dropdown-active::before {
  content: ' ';
  display: block;
  position: absolute;
  background: #f0f0f0;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}
.selectize-dropdown {
  position: absolute;
  z-index: 10;
  border: 1px solid rgba(0, 40, 100, 0.12);
  background: #fff;
  margin: -1px 0 0 0;
  border-top: 0 none;
  box-sizing: border-box;
  border-radius: 0 0 3px 3px;
  height: auto;
  padding: 0;
}
.selectize-dropdown [data-selectable] {
  cursor: pointer;
  overflow: hidden;
}
.selectize-dropdown [data-selectable] .highlight {
  background: rgba(125, 168, 208, 0.2);
  border-radius: 1px;
}
.selectize-dropdown [data-selectable], .selectize-dropdown .optgroup-header {
  padding: 6px .75rem;
}
.selectize-dropdown .optgroup:first-child .optgroup-header {
  border-top: 0 none;
}
.selectize-dropdown .optgroup-header {
  color: #495057;
  background: #fff;
  cursor: default;
}
.selectize-dropdown .active {
  background-color: #F1F4F8;
  color: #467fcf;
}
.selectize-dropdown .active.create {
  color: #495057;
}
.selectize-dropdown .create {
  color: rgba(48, 48, 48, 0.5);
}
.selectize-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  -webkit-overflow-scrolling: touch;
}
.selectize-control.single .selectize-input, .selectize-control.single .selectize-input input {
  cursor: pointer;
}
.selectize-control.single .selectize-input.input-active, .selectize-control.single .selectize-input.input-active input {
  cursor: text;
}
.selectize-control.single .selectize-input:after {
  content: '';
  display: block;
  position: absolute;
  top: 13px;
  right: 12px;
  width: 8px;
  height: 10px;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath fill='#999' d='M0 0L10 0L5 5L0 0'/%3E%3C/svg%3E") no-repeat center;
  background-size: 8px 10px;
  transition: .3s transform;
}
.selectize-control.single .selectize-input.dropdown-active:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.selectize-control .selectize-input.disabled {
  opacity: 0.5;
  background-color: #fafafa;
}
.selectize-dropdown .image, .selectize-input .image {
  width: 1.25rem;
  height: 1.25rem;
  background-size: contain;
  margin: -1px .5rem -1px -4px;
  line-height: 1.25rem;
  float: left;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.selectize-dropdown .image img, .selectize-input .image img {
  max-width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  border-radius: 2px;
}
.selectize-input .image {
  width: 1.5rem;
  height: 1.5rem;
  margin: -3px .75rem -3px -5px;
}
